import AnimatedExpand from '@components/core/AnimatedExpand'
import {
  type DevelopmentOnlyProps,
  withDevelopmentOnly,
} from '@components/core/DevelopmentOnly'
import { usePermissions } from '@hooks/usePermissions'
import { usePrefetch } from '@hooks/usePrefetch'
import HomeIcon from '@icons/home-01.svg'
import AoaIcon from '@icons/line-chart-up-03.svg'
import OrganizationIcon from '@icons/user-circle.svg'
import { useI18nContext } from '@packages/i18n'
import type { GsPermission } from '@packages/permissions'
import { cn } from '@utils'
import {
  type FunctionComponent,
  type SVGProps,
  useCallback,
  useMemo,
} from 'react'
import { NavLink, useMatch, useMatches } from 'react-router-dom'

type MenuItem = {
  allowOpenMenu?: boolean
  end?: boolean
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  isCollapsed?: boolean
  label: string
  link: string
  links?: MenuItem[]
  readonly onNavigate?: () => void
  prefetch?: string | boolean
  previewForStages?: DevelopmentOnlyProps['stages']
  requiredPermissions: GsPermission[]
}

const LinkItem = (item: MenuItem) => {
  const { handlePrefetch, clearPrefetchFlag } = usePrefetch(
    item.prefetch
      ? typeof item.prefetch === 'string'
        ? item.prefetch
        : item.link
      : undefined
  )

  const match = useMatch(item.link)
  const matches = useMatches()

  const linksOpen = useMemo(() => {
    if (!item.links?.length) return false

    if (match) return true

    const childrenMatch = matches.some(({ pathname }) => pathname === item.link)
    return Boolean(childrenMatch)
  }, [item.link, item.links?.length, match, matches])

  return withDevelopmentOnly(
    Boolean(item.previewForStages),
    <>
      <NavLink
        className={({ isActive, isPending }) =>
          cn(
            'group grid grid-cols-[auto_1fr] gap-x-3 rounded-md p-2.5 font-sans text-sm font-semibold text-white',
            {
              // 'bg-white/10': (isActive || isPending) && !linksOpen,
              'bg-white/10':
                (isActive || isPending) && (item.end || item.isCollapsed),

              'group-hover:bg-transparent':
                (isActive || isPending) && !item.end && item.links?.length,
              'hover:bg-white/5': !(isActive || isPending) || linksOpen,
            }
          )
        }
        end={item.end}
        onClick={() => {
          if (item.links?.length && item.allowOpenMenu) {
            clearPrefetchFlag()
          } else {
            clearPrefetchFlag()
            item.onNavigate?.()
          }
        }}
        onMouseEnter={handlePrefetch}
        to={item.link}
      >
        {item.icon ? (
          <item.icon aria-hidden='true' className='size-5 shrink-0' />
        ) : (
          <div className='h-5 w-[26px]' />
        )}
        <div
          className={cn(
            'w-auto overflow-hidden whitespace-nowrap text-sm font-medium transition-opacity',
            {
              'opacity-0 group-hover:opacity-100': item.isCollapsed,
              'opacity-100': !item.isCollapsed,
            }
          )}
        >
          {item.label}
          {item.previewForStages && (
            <span className='ml-4 text-xs font-normal text-gray-400'>
              (Preview)
            </span>
          )}
        </div>
      </NavLink>
      {item.links && (
        <AnimatedExpand
          className={cn({
            'group-hover:grid-rows-[1fr] group-hover:opacity-100':
              item.isCollapsed && linksOpen,
          })}
          open={linksOpen && !item.isCollapsed}
        >
          <div className='space-y-1'>
            {item.links.map((link) => (
              <LinkItem
                isCollapsed={item.isCollapsed}
                {...link}
                allowOpenMenu={item.allowOpenMenu}
                key={link.link}
                onNavigate={item.onNavigate}
              />
            ))}
          </div>
        </AnimatedExpand>
      )}
    </>,
    { stages: item.previewForStages }
  )
}

type SidebarMenuProps = {
  readonly allowOpenMenu?: boolean
  readonly isCollapsed?: boolean
  readonly onNavigate?: () => void
}

const SidebarMenu = ({
  isCollapsed,
  onNavigate,
  allowOpenMenu,
}: SidebarMenuProps) => {
  const { LL } = useI18nContext()

  const { checkOtherPermission } = usePermissions([])

  const filterNavbar = useCallback(
    (items: MenuItem[]) => {
      return items
        .filter((item) => checkOtherPermission(item.requiredPermissions))
        .map((item) => ({
          ...item,
          links: item.links?.filter((link) =>
            checkOtherPermission(link.requiredPermissions)
          ),
        }))
    },
    [checkOtherPermission]
  )

  const menuItems = filterNavbar([
    {
      icon: HomeIcon,
      label: LL.navigation.sidebar.home(),
      link: '/',
      requiredPermissions: [],
    },
    {
      icon: AoaIcon,
      label: LL.navigation.sidebar.aoa.label(),
      link: '/aoa',
      links: [
        {
          end: true,
          label: LL.navigation.sidebar.aoa.subItems.overview(),
          link: '/aoa',
          prefetch: true,
          requiredPermissions: ['Aoa:Read'],
        },
        {
          end: true,
          label: LL.navigation.sidebar.aoa.subItems.checkin(),
          link: '/aoa/checkin',
          prefetch: true,
          requiredPermissions: ['Checkins:Read'],
        },
        {
          end: true,
          label: LL.navigation.sidebar.aoa.subItems.filterPage(),
          link: '/aoa/activity',
          prefetch: true,
          previewForStages: ['ci-*', 'dev', 'ln', 'ap'],
          requiredPermissions: ['Checkins:Read', 'Aoa:Read'],
        },
        {
          end: true,
          label: LL.navigation.sidebar.aoa.subItems.heatmap(),
          link: '/aoa/heatmap',
          prefetch: true,
          previewForStages: ['ci-*', 'dev', 'ln', 'ap'],
          requiredPermissions: ['Checkins:Read', 'Aoa:Read'],
        },
        {
          end: true,
          label: 'Flowchart',
          link: '/aoa/flowchart',
          prefetch: true,
          previewForStages: ['ci-*', 'dev', 'ln', 'ap'],
          requiredPermissions: ['Checkins:Read', 'Aoa:Read'],
        },
        {
          end: true,
          label: 'DevPage',
          link: '/aoa/devpage',
          prefetch: true,
          previewForStages: ['ci-*', 'dev', 'ln', 'ap'],
          requiredPermissions: ['Checkins:Read', 'Aoa:Read'],
        },
      ],
      prefetch: '/aoa',
      requiredPermissions: ['Aoa:Read'],
    },
    {
      icon: OrganizationIcon,
      label: LL.navigation.sidebar.organization.label(),
      link: '/organization',
      links: [
        {
          end: true,
          label: LL.navigation.sidebar.organization.subItems.overview(),
          link: '/organization',
          prefetch: true,
          requiredPermissions: ['Members:Read'],
        },
        {
          end: true,
          label: LL.navigation.sidebar.organization.subItems.members(),
          link: '/organization/members',
          prefetch: true,
          requiredPermissions: ['Members:Read'],
        },
      ],
      prefetch: '/organization',
      requiredPermissions: ['Members:Read'],
    },
  ] satisfies MenuItem[])

  const links = menuItems.map((item) => (
    <LinkItem
      allowOpenMenu={allowOpenMenu}
      isCollapsed={isCollapsed}
      onNavigate={onNavigate}
      {...item}
      key={item.link}
    />
  ))

  return <div className='space-y-1'>{links}</div>
}

export default SidebarMenu
