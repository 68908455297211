import type {
  AuthNextSignInStep,
  AuthNextSignUpStep,
} from '@aws-amplify/auth/dist/esm/types'
import LoginForm from '@components/auth/LoginForm'
import PickNewPassword from '@components/auth/PickNewPassword'
import TenantAliasForm from '@components/auth/TenantAliasForm'
import { useAuth, useTenant } from '@hooks'
import { Button, LoadingOverlay, Text, Title } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import { IconChevronLeft } from '@tabler/icons-react'
import type { DefaultAuthInfo, TenantLoginInfo } from '@types'
import { ReactTranslation } from '@utils'
import { clearAuthQueryStrings } from '@utils/auth-utils'
import { translateCognitoError } from '@utils/error-utils'
import { setCurrentAmplifyUser } from '@utils/localstorage-utils'
import {
  AuthError,
  type ResendSignUpCodeOutput,
  confirmSignIn,
  resendSignUpCode,
  signIn,
  signUp,
} from 'aws-amplify/auth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ConfirmEmailForm from './ConfirmEmailForm'
import ResetPasswordForm from './ResetPasswordForm'
import ResetPasswordSubmitForm from './ResetPasswordSubmitForm'
import SignUpForm from './SignUpForm'

enum FormState {
  ALIAS = 'ALIAS',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SENT = 'FORGOT_PASSWORD_SENT',
  FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSWORD_SUBMIT',
  LOGIN = 'LOGIN',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SIGN_UP = 'SIGN_UP',
}

export type AuthWrapperProps = {
  readonly authflow?: 'signup'
  readonly forcedLoginInfo?: TenantLoginInfo
  readonly forcedUsername?: string
  readonly onComplete: () => void
}

const AuthWrapper = ({
  onComplete,
  forcedLoginInfo,
  forcedUsername,
  authflow,
}: AuthWrapperProps) => {
  const { defaultAuthInfo, getTenantInfoForAlias } = useTenant()
  const { ssoSignIn, sessionRestoreFinished } = useAuth<true>()

  const { LL } = useI18nContext()

  const initialValues = useMemo(() => {
    const query = new URLSearchParams(window.location.search)
    const queryAuthFlow = query.get('authflow') ?? authflow

    let initialFormState: FormState = FormState.LOGIN
    if (queryAuthFlow === 'signup') initialFormState = FormState.SIGN_UP
    if (queryAuthFlow === 'signin') initialFormState = FormState.LOGIN
    if (queryAuthFlow === 'reset-password')
      initialFormState = FormState.FORGOT_PASSWORD_SUBMIT

    return {
      alias: query.get('org'),
      confirmation: query.get('confirmation'),
      initialFormState,
      password: query.get('password'),
      username: query.get('username'),
    }
  }, [authflow])

  const [formState, setFormState] = useState<keyof typeof FormState>(
    initialValues.initialFormState
  )

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')

  const [signUpResponse, setSignUpResponse] = useState<
    | (Partial<ResendSignUpCodeOutput> & {
        email: string
        password?: string
      })
    | undefined
  >()

  const [isLoading, setIsLoading] = useState(false)

  const [tenantLoginInfo, setTenantLoginInfo] = useState<
    TenantLoginInfo | undefined
  >(forcedLoginInfo)

  const [signInError, setSignInError] = useState<string>('')

  const authInfo: DefaultAuthInfo = useMemo(() => {
    if (forcedLoginInfo) {
      return {
        ...defaultAuthInfo.authInfo,
        ...forcedLoginInfo.authSettings,
      }
    }

    return {
      ...defaultAuthInfo?.authInfo,
      ...tenantLoginInfo?.authSettings,
    }
  }, [defaultAuthInfo?.authInfo, forcedLoginInfo, tenantLoginInfo])

  const handleNewAlias = useCallback(
    async (newAlias: string) => {
      try {
        const newTenantInfo = await getTenantInfoForAlias(newAlias)
        if (newTenantInfo) {
          setTenantLoginInfo(newTenantInfo)
          setFormState('LOGIN')
        } else return newTenantInfo

        setIsLoading(false)

        return true
      } catch {
        setIsLoading(false)
        return false
      }
    },
    [getTenantInfoForAlias]
  )

  const handleNextStep = useCallback(
    ({
      isSignedIn,
      nextStep,
    }: {
      isSignedIn: boolean
      nextStep: AuthNextSignInStep | AuthNextSignUpStep
    }) => {
      if (isSignedIn) {
        clearAuthQueryStrings()
        onComplete()
      }

      let nextStepName:
        | AuthNextSignInStep['signInStep']
        | AuthNextSignUpStep['signUpStep']
        | undefined

      if ('signInStep' in nextStep) {
        nextStepName = nextStep.signInStep
      } else if ('signUpStep' in nextStep) {
        nextStepName = nextStep.signUpStep
      }

      switch (nextStepName) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED': {
          setFormState('NEW_PASSWORD_REQUIRED')
          break
        }
        case 'CONFIRM_SIGN_UP': {
          setFormState('CONFIRM_ACCOUNT')
          break
        }
        case 'COMPLETE_AUTO_SIGN_IN': {
          setFormState('LOGIN')
          break
        }
        default:
          break
      }
    },
    [onComplete]
  )

  const handleSignIn = useCallback(
    async (credentials: { password: string; username: string }) => {
      setSignInError('')

      setIsLoading(true)
      try {
        setCurrentAmplifyUser({ username: undefined })
        const result = await signIn({
          password: credentials.password,
          username: credentials.username,
        })
        if (result.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          const resendResult = await resendSignUpCode({
            username: credentials.username,
          })
          setSignUpResponse({
            attributeName: resendResult.attributeName,
            deliveryMedium: resendResult.deliveryMedium,
            destination: resendResult.destination,
            email: credentials.username,
            password: credentials.password,
          })
        }

        handleNextStep(result)
      } catch (error) {
        if (
          error instanceof AuthError &&
          error.name === 'UserNotConfirmedException'
        ) {
          await resendSignUpCode({
            username: credentials.username,
          })
            .then((result) => {
              setSignUpResponse({
                ...result,
                email: credentials.username,
                password: credentials.password,
              })
              setFormState('CONFIRM_ACCOUNT')
              setTimeout(() => setIsLoading(false), 2_000)
            })
            .catch((anotherError) => {
              setSignInError(translateCognitoError(LL, anotherError))
              setIsLoading(false)
            })
        } else {
          setSignInError(translateCognitoError(LL, error))
          setIsLoading(false)
        }
      }
    },
    [LL, handleNextStep]
  )

  const handleSignUp = useCallback(
    async (credentials: { password: string; username: string }) => {
      setSignInError('')

      setIsLoading(true)
      try {
        const result = await signUp(credentials)
        const codeDeliveryDetails =
          result.nextStep && 'codeDeliveryDetails' in result.nextStep
            ? result.nextStep.codeDeliveryDetails
            : undefined
        setSignUpResponse({
          attributeName: codeDeliveryDetails?.attributeName,
          deliveryMedium: codeDeliveryDetails?.deliveryMedium,
          destination: codeDeliveryDetails?.destination,
          email: credentials.username,
          password: credentials.password,
        })

        if (result.isSignUpComplete === false) {
          setFormState('CONFIRM_ACCOUNT')
        }
      } catch (error) {
        setSignInError(translateCognitoError(LL, error))
      } finally {
        setIsLoading(false)
      }
    },
    [LL]
  )

  const handleNewPassword = useCallback(
    async (newPassword: string) => {
      setIsLoading(true)
      setSignInError('')

      try {
        const result = await confirmSignIn({ challengeResponse: newPassword })
        handleNextStep(result)
      } catch (error) {
        setSignInError(translateCognitoError(LL, error))
      }

      setIsLoading(false)
    },
    [LL, handleNextStep]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (initialValues.alias) {
      setIsLoading(true)
      void handleNewAlias(initialValues.alias).then((success) => {
        if (success && initialValues.username && initialValues.password) {
          handleSignIn({
            password: initialValues.password,
            username: initialValues.username,
          }).catch(() => setIsLoading(false))
        }
      })
    } else if (initialValues.username && initialValues.password) {
      void handleSignIn({
        password: initialValues.password,
        username: initialValues.username,
      }).catch(() => setIsLoading(false))
    }
  }, [])

  if (!sessionRestoreFinished)
    return <LoadingOverlay id='sessionRestoreFinished' visible />

  return (
    <div className='mx-auto max-w-2xl'>
      {(formState === 'ALIAS' ||
        formState === FormState.FORGOT_PASSWORD ||
        formState === FormState.FORGOT_PASSWORD_SENT ||
        Boolean(tenantLoginInfo)) &&
        !forcedLoginInfo && (
          <Button
            color='black'
            leftSection={<IconChevronLeft />}
            mb='md'
            ml='-4px'
            onClick={() => {
              setFormState('LOGIN')
              setTenantLoginInfo(undefined)
            }}
            p='0'
            variant='white'
          >
            {LL.buttons.back()}
          </Button>
        )}
      {formState === 'ALIAS' && (
        <div>
          <Title mb='xl' order={4}>
            {LL.auth.enterpriseLogin()}
          </Title>
          <TenantAliasForm
            onSubmit={async (newAlias) => await handleNewAlias(newAlias.alias)}
          />
        </div>
      )}
      {formState === 'LOGIN' && authInfo && (
        <div>
          <Title c='gray.9' order={4}>
            {tenantLoginInfo?.displayName ?? LL.auth.signIn()}
          </Title>
          <Text c='gray.6' mb='xl' mt='sm'>
            {LL.auth.signInSubtitle()}
          </Text>
          <LoginForm
            authInfo={authInfo}
            error={signInError}
            forcedUsername={forcedUsername}
            isLoading={isLoading}
            onForgotPassword={(email) => {
              setForgotPasswordEmail(email)
              setSignInError('')
              setFormState('FORGOT_PASSWORD')
            }}
            onSSOLogin={async (providerName) => {
              if (authInfo.identityProviders)
                await ssoSignIn(providerName, authInfo)
            }}
            onSignUp={() => {
              setSignInError('')
              setFormState('SIGN_UP')
            }}
            onSubmit={handleSignIn}
          />
          {!forcedLoginInfo && (
            <Button
              c='primary.6'
              display='block'
              mt='xl'
              mx='auto'
              onClick={() => setFormState('ALIAS')}
              variant='white'
            >
              {tenantLoginInfo
                ? LL.auth.switchOrganization()
                : LL.auth.switchToSso()}
            </Button>
          )}
        </div>
      )}
      {formState === 'NEW_PASSWORD_REQUIRED' &&
        Boolean(initialValues.confirmation) && (
          <div>
            <Title mb='xl' order={4}>
              {LL.auth.newPasswordRequired()}
            </Title>
            <PickNewPassword
              errorMessage={signInError}
              isLoading={isLoading}
              onSubmit={handleNewPassword}
            />
          </div>
        )}
      {formState === 'FORGOT_PASSWORD_SUBMIT' &&
        Boolean(initialValues.confirmation) && (
          <div>
            <Title mb='xl' order={4}>
              {LL.auth.pickNewPassword()}
            </Title>
            <ResetPasswordSubmitForm
              onSubmit={async (values) => {
                await handleSignIn(values)
                return
              }}
            />
          </div>
        )}
      {formState === 'SIGN_UP' && (
        <div>
          <Title mb='xl' order={4}>
            {LL.auth.signUp()}
          </Title>
          <SignUpForm
            error={signInError}
            forcedUsername={forcedUsername}
            isLoading={isLoading}
            onSignIn={() => setFormState('LOGIN')}
            onSubmit={handleSignUp}
          />
        </div>
      )}
      {formState === 'CONFIRM_ACCOUNT' && signUpResponse && (
        <div>
          <Title mb='xs' order={4}>
            {LL.auth.confirmAccount()}
          </Title>
          <Text mb='xl'>
            <ReactTranslation
              message={LL.auth.confirmationCodeHasBeenSentTo()}
              renderComponent={() => {
                return (
                  <strong>
                    {signUpResponse.destination?.includes('@')
                      ? signUpResponse.email
                      : signUpResponse.destination}
                  </strong>
                )
              }}
            />
          </Text>
          <ConfirmEmailForm
            onComplete={() => {
              if (signUpResponse.password && signUpResponse.email) {
                void handleSignIn({
                  password: signUpResponse.password,
                  username: signUpResponse.email,
                })
              } else {
                setFormState('LOGIN')
              }
            }}
            username={signUpResponse.email}
          />
        </div>
      )}
      {formState === 'FORGOT_PASSWORD' && (
        <div>
          <Title mb='xs' order={4}>
            {LL.auth.resetPassword()}
          </Title>
          <Text mb='xl'>{LL.auth.resetPasswordDescription()}</Text>
          <ResetPasswordForm
            initialEmail={forgotPasswordEmail}
            onComplete={() => {
              setFormState('FORGOT_PASSWORD_SENT')
            }}
            onConfirmEmail={(resendInfo, email) => {
              setSignUpResponse({
                email,
                ...resendInfo,
              })
              setFormState('CONFIRM_ACCOUNT')
            }}
          />
        </div>
      )}
      {formState === FormState.FORGOT_PASSWORD_SENT && (
        <div>
          <Title mb='xs' order={4}>
            {LL.auth.checkInbox()}
          </Title>
          <Text mb='xl'>{LL.auth.checkInboxDescription()}</Text>
        </div>
      )}
    </div>
  )
}

export default AuthWrapper
