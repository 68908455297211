import { ActionIcon, type ActionIconProps } from '@mantine/core'
import { IconCheck, IconDots, IconX } from '@tabler/icons-react'
import type { TablerIcon, TablerIconProps } from '@types'
import {
  type ComponentPropsWithoutRef,
  type MouseEventHandler,
  forwardRef,
} from 'react'

const commonActionIconProps = {
  variant: 'light',
} satisfies ActionIconProps

const defaultIconSize = '1rem'

export type ButtonProps = ActionIconProps &
  ComponentPropsWithoutRef<'button'> & {
    readonly iconSize?: TablerIconProps['size']
    readonly onClick?: MouseEventHandler<HTMLButtonElement>
  }

const createActionButtonComponent = (
  name: string,
  Icon: TablerIcon,
  defaultActionIconProps?: ActionIconProps & ComponentPropsWithoutRef<'button'>
) => {
  const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ iconSize = defaultIconSize, ...userActionIconProps }, ref) => (
      <ActionIcon
        ref={ref}
        {...commonActionIconProps}
        {...defaultActionIconProps}
        {...userActionIconProps}
      >
        <Icon size={iconSize} />
      </ActionIcon>
    )
  )
  Button.displayName = name
  return Button
}

export const MenuButton = createActionButtonComponent('MenuButton', IconDots, {
  title: 'Open Menu',
  variant: 'subtle',
})

export const SaveButton = createActionButtonComponent('SaveButton', IconCheck, {
  color: 'green',
  title: 'Save',
})

export const CancelButton = createActionButtonComponent('CancelButton', IconX, {
  title: 'Cancel',
})
