import { getResolvedDomains } from '@packages/constants'
import {
  TRPCClientError,
  type TRPCLink,
  createTRPCClient,
  httpLink,
} from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'
import type { AppRouter } from '../../../../backend'
import { getIdTokenJwt } from './auth-utils'

const baseUrl =
  process.env['API_URL'] ??
  `https://${getResolvedDomains(window.location.hostname).apiRecord}`

export const authorizedHeaders = async () => {
  let tenantId: string | undefined

  try {
    const rawTenantId = localStorage.getItem('tenant-id')
    const parsed = JSON.parse(rawTenantId ?? '')
    if (parsed && typeof parsed === 'string' && parsed.length === 26)
      tenantId = parsed
    else localStorage.removeItem('tenant-id')
  } catch {
    localStorage.removeItem('tenant-id')
  }

  const headers: Record<string, string> & {
    authorization?: string
    'x-tenant'?: string
  } = {}

  try {
    const idTokenJwt = await getIdTokenJwt()
    if (idTokenJwt) headers.authorization = `Bearer ${idTokenJwt}`

    if (tenantId) headers['x-tenant'] = tenantId
  } catch {
    /* empty */
  }

  return headers
}

export const hasApiToken = async () => {
  return Boolean(await getIdTokenJwt())
}

export const trpc = createTRPCReact<AppRouter>()

export const trpcLinks: TRPCLink<AppRouter>[] = [
  // create a custom ending link
  (runtime) => {
    // initialize the different links for different targets
    const servers = {
      account: httpLink({
        headers: async () => ({
          authorization: (await authorizedHeaders()).authorization ?? '',
        }),
        url: `${baseUrl}/account`,
      })(runtime),
      aoa: httpLink({
        headers: authorizedHeaders,
        url: `${baseUrl}/aoa`,
      })(runtime),
      organization: httpLink({
        headers: authorizedHeaders,
        url: `${baseUrl}/organization`,
      })(runtime),
      'public-router': httpLink({
        headers: async () => ({
          'x-tenant': (await authorizedHeaders())['x-tenant'],
        }),
        url: `${baseUrl}/public-router`,
      })(runtime),
    }

    return (context) => {
      const { op } = context
      // split the path by `.` as the first part will signify the server target name
      const pathParts = op.path.split('.')

      // first part of the query should be `server1` or `server2`
      const serverName = pathParts.shift() as string as keyof typeof servers

      // combine the rest of the parts of the paths
      // -- this is what we're actually calling the target server with
      const path = pathParts.join('.')

      const link = servers[serverName]

      // FIXME: this doesn't work asynchonously
      // if (
      //   ['account', 'aoa', 'organization'].includes(serverName) &&
      //   !authorizedHeaders().authorization
      // ) {
      //   throw new TRPCClientError('Unauthorized')
      // }

      return link({
        ...context,
        op: {
          ...op,
          // override the target path with the prefix removed
          path,
        },
      })
    }
  },
]

export const isTRPCClientError = (
  cause: unknown
): cause is TRPCClientError<AppRouter> => {
  return cause instanceof TRPCClientError
}

export const retryExcept404 = (
  failureCount: number,
  error: Pick<TRPCClientError<AppRouter>, 'data'>
) => error.data?.code !== 'NOT_FOUND' && failureCount < 5

export const manuallyFetchAccountResponse = async (props: {
  baseUrlOverride?: string
  idToken: string
}) => {
  try {
    const accountClient = createTRPCClient<AppRouter>({
      links: [
        (runtime) => {
          const link = httpLink({
            headers() {
              return {
                authorization: `Bearer ${props.idToken}`,
              }
            },
            url: `${props.baseUrlOverride ?? baseUrl}/account`,
          })(runtime)

          return (context) =>
            link({
              ...context,
              op: {
                ...context.op,
                path: 'getUserAccount',
              },
            })
        },
      ],
    })

    const accountResponse =
      await accountClient.account.getUserAccount.query(undefined)

    return accountResponse
  } catch {
    return undefined
  }
}
