import AppShell from '@components/layout/sidebar/AppShell'
import { useAuth } from '@hooks'
import { useSentryContext } from '@hooks/useSentryContext'
import { Loader, LoadingOverlay } from '@mantine/core'
import { NavigationProgress } from '@mantine/nprogress'
import { getResolvedDomains } from '@packages/constants'
import { throwUnknownError } from '@utils/error-utils'
import { Suspense, useMemo } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import DomainPrefixRedirectPage from './authentication/DomainPrefixRedirectPage'
import LoginPage from './authentication/LoginPage'
import PickTenantPage from './authentication/PickTenantPage'
import SetupProfilePage from './authentication/SetupProfilePage'
import SpecificTenantAuthPage from './authentication/SpecificTenantAuthPage'

export const AppWrapper = () => {
  const {
    sessionRestoreFinished,
    sessions,
    user,
    tenant,
    userRestoreFinished,
    sessionRestoreRunning,
    tenantId,
  } = useAuth()

  useSentryContext()

  const [searchParameters] = useSearchParams()

  const { domainPrefix } = getResolvedDomains(window.location.hostname)

  const specificTenantId = useMemo(() => {
    return searchParameters.get('tenantId') ?? searchParameters.get('tenant')
  }, [searchParameters])

  const ssoError = useMemo(() => {
    return searchParameters.get('error_description')
  }, [searchParameters])

  if (ssoError) {
    throwUnknownError({
      message: ssoError,
      statusCode: 500,
    })
    return null
  }

  if (domainPrefix) {
    return <DomainPrefixRedirectPage hostname={window.location.hostname} />
  }

  if (sessionRestoreFinished === false || sessionRestoreRunning === true) {
    return <LoadingOverlay id='authwrapper-sessionrestore' visible />
  }

  if (!(user || userRestoreFinished)) {
    return <LoadingOverlay id='authwrapper-user-not-loaded' visible />
  }

  if (specificTenantId) {
    return <SpecificTenantAuthPage tenantId={specificTenantId} />
  }

  if (window.location.search.includes('authflow')) {
    return <LoginPage />
  }

  if (!Object.keys(sessions).length) {
    return <LoginPage />
  }

  if (user && user.userSetupFinished === false)
    return <SetupProfilePage username={user.username} />

  if (!(tenant && user && tenantId)) {
    return <PickTenantPage />
  }

  return (
    <>
      <NavigationProgress color='accent.5' />
      <AppShell>
        <Suspense fallback={<Loader id='AppWrapper.suspense' />}>
          <Outlet />
        </Suspense>
      </AppShell>
    </>
  )
}

export default AppWrapper
