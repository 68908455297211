import type { IterationCycle } from '@packages/shapes'
import type { AoaApprovableStatus, AoaInfo, DT } from '@packages/types'
import _ from 'lodash'
import { isActiveAoa, isExpiredAoa } from './iteration-utils'

export const showRelevantAoaForSeries = <
  T extends {
    aoaId: string
    iteration?: AoaInfo['iteration']
    level: number
    seriesId?: string | undefined
    status: AoaApprovableStatus
  },
>(
  aoas: Readonly<T[]>,
  cycles: (IterationCycle & DT)[] | undefined,
  options?: {
    showActiveAoasOnly?: boolean
  }
): T[] => {
  const aoaBySeries = _(aoas).groupBy('seriesId').value()

  const result: T[] = []

  for (const aoaList of Object.values(aoaBySeries)) {
    if (!aoaList) continue

    const firstNotExpiredAoa = aoaList.find(
      (aoa) =>
        !isExpiredAoa({ cycles, iteration: aoa.iteration }) && aoa.seriesId
    )

    const firstActiveAoa = aoaList.find(
      (aoa) =>
        isActiveAoa(
          aoa,
          cycles,
          aoaList.filter((item) => item.seriesId === aoa.seriesId)
        ) && aoa.seriesId
    )

    if (firstActiveAoa) result.push(firstActiveAoa)
    else if (!options?.showActiveAoasOnly && firstNotExpiredAoa)
      result.push(firstNotExpiredAoa)
    else if (!options?.showActiveAoasOnly) {
      const lastExpiredAoa = aoaList.at(-1)
      if (lastExpiredAoa) result.push(lastExpiredAoa)
    }
  }

  for (const aoa of aoas) {
    if (!aoa.seriesId) result.push(aoa)
  }

  return result
}
